import Layout from '@/layout/index'
import LayoutHoutai from '@/LayoutHoutai/index'

/**
 * 注意：子菜单仅在路由子菜单时显示。长度>=1
 *
 * hidden: true                   如果设置为true，则该项将不会显示在侧边栏中（默认为false）
 * alwaysShow: true               如果设置为true，将始终显示根菜单
 *                                如果未设置alwaysShow，则当项目在路线中有多个子项时，
 *                                它将变为嵌套模式，否则不显示根菜单
 * redirect: noRedirect           重定向：noRedirect如果设置noRedirect，则面包屑中不会重定向
 * name:'router-name'             名称：'router-name'名称由＜keep-alive＞使用（必须设置！！！）
 * role:[]                        权限  如果需要区分权限必写，并且为数组格式
 * meta : {
    title: 'title'               侧边栏和面包屑中显示的名称（推荐设置）
    icon: 'svg-name'/'el-icon-x' 侧边栏中显示的图标
    noCache: true                如果设置为true，则不会缓存页面（默认为false）
    breadcrumb: false            如果设置为false，则该项将隐藏在面包屑中（默认为true）
    activeMenu: '/example/list'  如果设置路径，侧边栏将突出显示您设置的路径
  }
 */

var router = [
    {/* 首页 */
        path: '/',
        component: Layout,
        redirect: '/',
        meta: { title: 'home1', },
        children: [
            {
                path: '',
                component: () => import('@/views/Home/HomeView'),
                name: 'Dashboard',
                meta: { title: '首页', }
            },
        ]
    },
    {/* 科技服务 */
        path: '/kejifuwu',
        component: Layout,
        redirect: '/kejifuwu',
        meta: { title: '科技服务', gundongNoshow: true, icon: 'kjfwicon' },
        children: [
            {
                hidden: true,
                path: '',
                component: () => import('@/views/PageView/index'),
                name: 'kejifuwu',
                meta: { title: '科技服务', titleEn: 'Technology services', gundongNoshow: true, heardImg: '科技服务头图.jpg' }
            },
            {
                path: 'jyzcp',
                component: () => import('@/views/PageView/index'),
                name: 'jyzcp',
                meta: {
                    title: '基因组', titleEn: 'Genome', gundongNoshow: true, heardImg: '科技服务头图.jpg', type1_id: 1,
                    type2_id: 1,
                }
            },
            {
                path: 'ccxcp',
                component: () => import('@/views/PageView/index'),
                name: 'ccxcp',
                meta: {
                    title: '重测序', titleEn: 'Resequencing', gundongNoshow: true, heardImg: '科技服务头图.jpg', type1_id: 1,
                    type2_id: 2,
                }
            },
            {
                path: 'zlztkcp',
                component: () => import('@/views/PageView/index'),
                name: 'zlztkcp',
                meta: {
                    title: '转录组', titleEn: 'Transcriptome', gundongNoshow: true, heardImg: '科技服务头图.jpg', type1_id: 1,
                    type2_id: 3,
                }
            },
            {
                path: 'wswcp',
                component: () => import('@/views/PageView/index'),
                name: 'wswcp',
                meta: {
                    title: '微生物组', titleEn: 'Microbiome', gundongNoshow: true, heardImg: '科技服务头图.jpg', type1_id: 1,
                    type2_id: 4,
                }
            },
            {
                path: 'dxzcp',
                component: () => import('@/views/PageView/index'),
                name: 'dxzcp',
                meta: {
                    title: '代谢组', titleEn: 'Metabolome', gundongNoshow: true, heardImg: '科技服务头图.jpg', type1_id: 1,
                    type2_id: 5,
                }
            },
            {
                path: 'dbzzcp',
                component: () => import('@/views/PageView/index'),
                name: 'dbzzcp',
                meta: {
                    title: '蛋白组', titleEn: 'Protein', gundongNoshow: true, heardImg: '科技服务头图.jpg', type1_id: 1,
                    type2_id: 6,
                }
            },
            {
                path: 'sjk',
                component: () => import('@/views/PageView/index'),
                name: 'sjk',
                meta: { title: '数据库', titleEn: 'Database', heardImg: '数据库头图.png' }
            },
        ]
    },
    {/* 智慧育种 */
        path: '/zhihuiyuzhong',
        component: Layout,
        redirect: '/zhihuiyuzhong',
        meta: { title: '智慧育种', gundongNoshow: true, icon: 'zhyzicon' },
        children: [
            {
                hidden: true,
                path: '',
                component: () => import('@/views/PageView/index'),
                name: 'zhihuiyuzhong',
                meta: { title: '智慧育种', titleEn: 'Smart breeding', gundongNoshow: true, heardImg: '智慧育种头图.jpg' }
            },
            {
                path: 'yxxp',
                component: () => import('@/views/PageView/index'),
                name: 'yxxp',
                meta: {
                    title: '液相芯片', titleEn: 'Fluorescent microscopy', gundongNoshow: true, heardImg: '智慧育种头图.jpg', type1_id: 2,
                    type2_id: 7,
                }
            },

            {
                path: 'dnazwtp',
                component: () => import('@/views/PageView/index'),
                name: 'dnazwtp',
                meta: {
                    title: 'DNA指纹图谱', titleEn: 'DNA fingerprinting', gundongNoshow: true, heardImg: '智慧育种头图.jpg', type1_id: 2,
                    type2_id: 8,
                }
            },
            {
                path: 'hxzzsx',
                component: () => import('@/views/PageView/index'),
                name: 'hxzzsx',
                meta: {
                    title: '核心种质筛选', titleEn: 'Core breeding selection', gundongNoshow: true, heardImg: '智慧育种头图.jpg', type1_id: 2,
                    type2_id: 9,
                }
            },
            {
                path: 'qjyzxzgs',
                component: () => import('@/views/PageView/index'),
                name: 'qjyzxzgs',
                meta: {
                    title: '全基因组选择', titleEn: 'Full genome selection', gundongNoshow: true, heardImg: '智慧育种头图.jpg', type1_id: 2,
                    type2_id: 10,
                }
            },
            {
                path: 'yzcrofw',
                component: () => import('@/views/PageView/index'),
                name: 'yzcrofw',
                meta: {
                    title: '育种CRO服务', titleEn: 'CRO services', gundongNoshow: true, heardImg: '智慧育种头图.jpg', type1_id: 2,
                    type2_id: 11,
                }
            },
            {
                path: 'fzyzsb',
                component: () => import('@/views/PageView/index'),
                name: 'fzyzsb',
                meta: {
                    title: '分子育种设备', titleEn: 'Cell culture', gundongNoshow: true, heardImg: '智慧育种头图.jpg', type1_id: 2,
                    type2_id: 12,
                }
            },
        ]
    },
    {/* 单细胞&时空 */
        path: '/danxibaoshihkong',
        component: Layout,
        redirect: '/danxibaoshihkong',
        meta: { title: '时空组学', gundongNoshow: true, icon: 'zlzxicon' },
        children: [
            {
                hidden: true,
                path: '',
                component: () => import('@/views/PageView/index'),
                name: 'danxibaoshihkong',
                meta: { title: '时空组学', titleEn: 'Spatiotemporalomics', gundongNoshow: true, heardImg: '时空组学头图.jpg' }
            },
            {
                path: 'dxbzlz',
                component: () => import('@/views/PageView/index'),
                name: 'dxbzlz',
                meta: {
                    title: '单细胞转录组', titleEn: 'Single-cell transcriptome', gundongNoshow: true, heardImg: '时空组学头图.jpg', type1_id: 3,
                    type2_id: 13,
                }
            },

            {
                path: 'dxbbxz',
                component: () => import('@/views/PageView/index'),
                name: 'dxbbxz',
                meta: {
                    title: '单细胞表观组', titleEn: 'Single-cell proteome', gundongNoshow: true, heardImg: '时空组学头图.jpg', type1_id: 3,
                    type2_id: 14
                }
            },
            {
                path: 'dxbmyzk',
                component: () => import('@/views/PageView/index'),
                name: 'dxbmyzk',
                meta: {
                    title: '单细胞免疫组库', titleEn: 'Single-cell immune repertoire', gundongNoshow: true, heardImg: '时空组学头图.jpg', type1_id: 3,
                    type2_id: 15
                }
            },
            {
                path: 'kzjlz',
                component: () => import('@/views/PageView/index'),
                name: 'kzjlz',
                meta: {
                    title: '空间转录组', titleEn: 'Spatial transcriptome', gundongNoshow: true, heardImg: '时空组学头图.jpg', type1_id: 3,
                    type2_id: 18
                }
            },
            {
                path: 'kjdxz',
                component: () => import('@/views/PageView/index'),
                name: 'kjdxz',
                meta: {
                    title: '空间代谢组', titleEn: 'Spatial metabolome', gundongNoshow: true, heardImg: '时空组学头图.jpg', type1_id: 3,
                    type2_id: 16
                }
            },
        ]
    },
    {/* 平台服务 */
        path: '/pingtaifuwu',
        component: Layout,
        redirect: '/pingtaifuwu',
        meta: { title: '平台服务', icon: '404' },
        children: [
            {
                hidden: true,
                path: '',
                component: () => import('@/views/PageView/index'),
                name: 'pingtaifuwu',
                meta: { title: '平台服务', icon: '404' }
            },
            {
                path: 'jzy',
                component: () => import('@/views/PageView/index'),
                name: 'jzy',
                meta: { title: '极智云数据分析平台', titleEn: 'Smart Cloud Data Analysis Platform', heardImg: '极智云背景.png' }
            },
            {
                path: 'sjk',
                component: () => import('@/views/PageView/index'),
                name: 'pingtaifuwusjk',
                meta: { title: '数据库', titleEn: 'Database', heardImg: '数据库头图.png' }
            },
            {
                path: 'znyzpt',
                component: () => import('@/views/PageView/index'),
                name: 'znyzpt',
                meta: { title: '智慧育种平台', titleEn: 'Smart Breeding Platform', heardImg: '智慧育种平台背景.png', }
            },
        ]
    },
    {/* 关于极智 */
        path: '/guyujizhi',
        component: Layout,
        redirect: '/guyujizhi',
        meta: { title: '关于极智', titleEn: 'About Smart' },
        children: [
            {
                hidden: true,
                path: '',
                component: () => import('@/views/PageView/index'),
                name: 'guyujizhi',
                meta: { title: '关于极智', titleEn: 'About Smart', heardImg: '关于极智头图.png' }
            },
            {
                path: 'gsjj',
                component: () => import('@/views/PageView/index'),
                name: 'gsjj',
                meta: { title: '公司简介', heardImg: ['关于极智头图.png', 'Nature文章的宣传.jpg'] }
            },
            {
                path: 'qywh',
                component: () => import('@/views/PageView/index'),
                name: 'qywh',
                meta: { title: '企业文化', heardImg: '关于极智头图.png' }
            },
            {
                path: 'fzlc',
                component: () => import('@/views/PageView/index'),
                name: 'fzlc',
                meta: { title: '发展历程', heardImg: '关于极智头图.png' }
            },
            {
                path: 'ryzz',
                component: () => import('@/views/PageView/index'),
                name: 'ryzz',
                meta: { title: '荣誉资质', heardImg: '关于极智头图.png' }
            },
            {
                path: 'yjcg',
                component: () => import('@/views/PageView/index'),
                name: 'yjcg',
                meta: { title: '研究成果', heardImg: '关于极智头图.png' }
            },
        ]
    },
    {/* 新闻资讯 */
        path: '/xinwenzixun',
        component: Layout,
        redirect: '/xinwenzixun',
        meta: { title: '新闻资讯', icon: '404' },
        children: [
            {
                path: '',
                component: () => import('@/views/PageView/index'),
                name: 'xinwenzixun',
                meta: { title: '新闻资讯', titleEn: 'News', heardImg: "新闻头图.png" }
            },
            {
                path: '/xinwenzixun/jzzx/:id',
                component: () => import('@/components/jzzxxq/index.vue'),
                hidden: true,
                name: 'jzzxxq',
                meta: { title: '文章详情', icon: 'dashboard', type: "xiangqing" }
            },
        ]
    },
    //     {/* 极智资讯详情 */
    //     path: '/xinwenzixun/jzzx',
    //     component: Layout,
    //     redirect: '/xinwenzixun/jzzx/:id',
    //     hidden: true,
    //     meta: { title: '资讯详情', icon: 'dashboard' },
    //     children: [

    //     ]
    // },
    {/* 资源中心 */
        path: '/ziyuanzhongxin',
        component: Layout,
        redirect: '/ziyuanzhongxin',
        alwaysShow: true,
        meta: { title: '资源中心', titleEn: 'Resource Center', icon: '404' },
        children: [
            {
                hidden: true,
                path: '',
                component: () => import('@/views/PageView/index'),
                name: 'ziyuanzhongxin',
                meta: { title: '资源中心', titleEn: 'Resource Center', icon: '404' }
            },
            {
                path: 'syjy',
                component: () => import('@/views/PageView/index'),
                name: 'syjy',
                meta: { title: '送样指南', heardImg: '资源中心头图.png' }
            },
            // {
            //     path: 'cpsc',
            //     component: () => import('@/views/PageView/index'),
            //     name: 'cpsc',
            //     meta: { title: '产品手册', heardImg:'资源中心头图.png'  }
            // },

        ]
    },


    {/* 关于我们 */
        path: '/About',
        component: Layout,
        redirect: '/About',
        alwaysShow: true,
        meta: { title: '联系我们', icon: 'dashboard' },
        children: [
            {
                // hidden: true,
                path: '',
                component: () => import('@/views/PageView/index'),
                name: 'About',
                meta: { title: '联系我们', titleEn: 'Contact us', heardImg: '联系我们头图.png' }
            },
            {
                path: 'zhaopin',
                component: () => import('@/views/PageView/index'),
                name: 'zhaopin',
                meta: { title: '人才招聘', titleEn: 'Recruitment', heardImg: '加入我们头图.png' }
            },
        ]
    },

    {/* 极智资讯详情 */
        path: '/kejifuwu/px',
        component: Layout,
        redirect: '/kejifuwu/px/:id',
        hidden: true,
        meta: { title: '资讯详情', },
        children: [
            {
                path: '/kejifuwu/px/:id',
                component: () => import('@/views/PageView/index'),
                hidden: true,
                name: 'kejifuwuxq',
                meta: { title: '', gundongNoshow: true }
            },
        ]
    },
    // {/* 极智资讯详情 */
    //     path: '/guyujizhi/jzzx',
    //     component: Layout,
    //     redirect: '/guyujizhi/jzzx',
    //     hidden:true,
    //     meta: { title: '资讯详情', icon: 'dashboard' },
    //     children: [
    //         {
    //             path: '',
    //             component: () => import('@/components/wenzhangContent/index.vue'),
    //             hidden:true,
    //             name: 'jzzxxq',
    //             meta: { title: '文章详情', icon: 'dashboard' }
    //         },
    //     ]
    // },

    {/* 后台管理 */
        path: '/smart-admin',
        component: LayoutHoutai,
        hidden: true,
        redirect: '/smart-admin/scvideo',
        meta: { title: '后台管理', icon: '404' },
        children: [
            {
                path: 'scvideo',
                component: () => import('@/views/houtai/scvideo'),
                name: 'scvideo',
                meta: { title: '上传视频' }
            },
            {
                path: 'cooperate',
                component: () => import('@/views/houtai/hezuodanwei'),
                name: 'cooperate',
                meta: { title: '关于图片与文章' }
            },
            // {
            //     path: 'bannarBox',
            //     component: () => import('@/views/houtai/lunbotu'),
            //     name: 'bannarBox',
            //     meta: { title: 'bannar图' }
            // },

            {
                path: 'joboffers',
                component: () => import('@/views/houtai/zhaopin'),
                name: 'joboffers',
                meta: { title: '招聘信息' }
            },
            {
                path: 'messageOnline',
                component: () => import('@/views/houtai/liuyan'),
                name: 'messageOnline',
                meta: { title: '在线留言' }
            },
            {
                path: 'operationEmail',
                component: () => import('@/views/houtai/operationEmail'),
                name: 'operationEmail',
                meta: { title: '维护运营邮箱' }
            },
            // {
            //     path: 'projectMaintain',
            //     component: () => import('@/views/houtai/project'),
            //     name: 'projectMaintain',
            //     meta: { title: '项目维护' }
            // },
            // {
            //     path: 'menuMaintain',
            //     component: () => import('@/views/houtai/menuMaintain'),
            //     name: 'menuMaintain',
            //     meta: { title: '菜单维护' }
            // },
            {
                path: 'article',
                component: { render: (e) => e("router-view") },
                redirect: '/smart-admin/article/articleList',
                meta: { title: '文章', },
                children: [
                    {
                        path: 'articleList',
                        component: () => import('@/views/houtai/wenzhangliebiao'),
                        name: 'articleList',
                        meta: { title: '产品与资讯', }
                    },
                    {
                        path: 'articleContent',
                        hidden: true,
                        component: () => import('@/views/houtai/wenzhangxiangqing'),
                        name: 'articleContent',
                        meta: { title: '{name}', activeMenu: '/smart-admin/article/cloudTools' }
                    },
                ]
            },
            {
                path: 'ziyuan',
                component: { render: (e) => e("router-view") },
                redirect: '/smart-admin/ziyuan/ziyuancontent',
                meta: { title: '文章', },
                children: [
                    {
                        path: 'ziyuancontent',
                        component: () => import('@/views/houtai/ziyuancontent'),
                        name: 'articleList',
                        meta: { title: '资源中心', }
                    },
                ]
            },
        ]
    },
]
export default router